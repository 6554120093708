import React from "react";
import {
  BankOutlined,
  CheckSquareOutlined,
  ContainerOutlined,
  HomeOutlined,
  ShopOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const navigationAdministrador = [
  {
    name: "Parametros",
    icon: <ToolOutlined />,
    items: [
      { name: "Inicio", icon: <HomeOutlined />, path: "/home" },
      { name: "Usuarios", icon: <UserOutlined />, path: "/users" },
      { name: "Empresas", icon: <BankOutlined />, path: "/companies" },
      { name: "Sucursales", icon: <ShopOutlined />, path: "/branchoffices" },
    ],
  },
  {
    name: "Inventarios",
    icon: <ContainerOutlined />,
    items: [
      { name: "Inventarios", icon: <UnorderedListOutlined />, path: "/inventories" },
      { name: "Carga maestra", icon: <UploadOutlined />, path: "/masterload" },
      { name: "Conteo productos", icon: <CheckSquareOutlined />, path: "/selectinv" },
    ],
  },
];
