import React from "react";
import { Input, Button } from "antd";
import { formatCurrencyCLP } from "../utils/formatPrices";

export const createPriceColumn = (title, dataIndex, key) => ({
  title,
  dataIndex,
  key,
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Buscar ${title}`}
        value={selectedKeys[0] || ""}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Buscar
      </Button>
      <Button
        onClick={() => {
          clearFilters();
          setSelectedKeys([]);
          confirm();
        }}
        size="small"
        style={{ width: 90 }}
      >
        Limpiar
      </Button>
    </div>
  ),
  onFilter: (value, record) => {
    // Manejo para dataIndex plano
    if (typeof dataIndex === "string") {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    }
    // Manejo para dataIndex anidado
    if (Array.isArray(dataIndex)) {
      const recordValue = dataIndex.reduce(
        (obj, key) => (obj ? obj[key] : ""),
        record
      );
      return recordValue.toString().toLowerCase().includes(value.toLowerCase());
    }
    return false;
  },
  render: (value) => formatCurrencyCLP(value, "currency"),
  sorter: (a, b) => {
    // Manejo para dataIndex plano
    if (typeof dataIndex === "string") {
      return a[dataIndex] - b[dataIndex];
    }
    // Manejo para dataIndex anidado
    if (Array.isArray(dataIndex)) {
      const aValue = dataIndex.reduce((obj, key) => (obj ? obj[key] : 0), a);
      const bValue = dataIndex.reduce((obj, key) => (obj ? obj[key] : 0), b);
      return aValue - bValue;
    }
    return 0;
  },
});
