import { IAxios, IAxiosFileDownload, IAxiosFormData } from "../conf";

const baseEndpoint = "Inventarios/";

const GetInventarioList = async (idSucursal) => {
  const endpoint = baseEndpoint + "List/" + idSucursal;

  return await IAxios.get(endpoint);
};

const GetInventarioInCourse = async () => {
  const endpoint = baseEndpoint + "InCourse";

  return await IAxios.get(endpoint);
};

const GetInventarioSelected = async (id) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.get(endpoint);
};

const GetInventarioToDownload = async (id) => {
  const endpoint = baseEndpoint + "Download/" + id;

  return await IAxiosFileDownload.get(endpoint);
};

const PutInventario = async (id, all, inventario, cargaMaestra) => {
  const endpoint = `${baseEndpoint}${id}/${all}`;
  const formData = new FormData();
  formData.append("idSucursal", inventario.idSucursal);
  // Añadir cada usuario por separado
  inventario.usuarios.forEach((usuario) => {
    formData.append("usuarios[]", usuario);
  });
  formData.append("comentario", inventario.comentario);
  formData.append("idEstadoInventario", inventario.idEstadoInventario);
  if (cargaMaestra) {
    formData.append("cargaMaestra", cargaMaestra);
  }

  return await IAxiosFormData.put(endpoint, formData);
};

const PostInventario = async (inventario, cargaMaestra) => {
  const formData = new FormData();
  formData.append("comentario", inventario.Comentario);
  formData.append("idSucursal", inventario.IdSucursal);
  // Añadir cada usuario por separado
  inventario.Usuarios.forEach((usuario) => {
    formData.append("usuarios[]", usuario);
  });
  formData.append("cargaMaestra", cargaMaestra);
  return await IAxiosFormData.post(baseEndpoint, formData);
};

const ApiInventarios = {
  GetInventarioList,
  GetInventarioInCourse,
  GetInventarioSelected,
  GetInventarioToDownload,
  PutInventario,
  PostInventario,
};

export default ApiInventarios;
