import { BankOutlined, ShopOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";

const CountInventorieHeader = ({ inventorie }) => {
  if (!inventorie) return <Card loading={true}></Card>;
  return (
    <Card>
      <Row gutter={[24, 16]}>
        <Col xs={5} style={{ textAlign: "center" }}>
          <br />
          <h6>{inventorie.correlativo}</h6>
        </Col>
        <Col xs={19}>
          <b>
            <BankOutlined /> {inventorie.empresa}
          </b>
          <br />
          <span>
            <ShopOutlined /> {inventorie.sucursal}
          </span>
          <br />
          {inventorie.fechaFormateada}
        </Col>
      </Row>
    </Card>
  );
};

export default CountInventorieHeader;
