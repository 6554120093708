import React, { useContext, useRef, useState } from "react";
import { Form, Row, Select, Col } from "antd";
import { toOptions } from "../../../../../js/helpers/helpers";
import { InventoriesContext } from "../../../../../js/app/context/InventoriesContext";

const { Item } = Form;

const InventoriesFilter = () => {
  const {
    parametersData,
    setInventories,
    setIdSucursalSelected,
  } = useContext(InventoriesContext);

  const { sucursales, empresas } = parametersData;

  const [sucursalesOptions, setSucursalesOptions] = useState([]);

  const formRef = useRef();

  const changeEmpresa = (value) => {
    const sucursalesFiltered =
      sucursales.filter((x) => x.idEmpresa === value) ?? [];
    formRef.current.setFields([
      { name: ["inventories", "idSucursal"], value: "" },
    ]);
    setIdSucursalSelected("");
    setInventories([]);
    setSucursalesOptions(sucursalesFiltered);
  };

  const changeSucursal = (value) => {
    setIdSucursalSelected(value);
  };
  return (
    <Form
      name="filterInventory"
      layout="vertical"
      requiredMark={false}
      ref={formRef}
    >
      <Row gutter={[24, 16]}>
        <Col md={12} xs={24}>
          <Item label="Empresa" name={["inventories", "idEmpresa"]}>
            <Select
              options={toOptions(empresas, "id", "nombre")}
              loading={!empresas}
              onChange={changeEmpresa}
            ></Select>
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item label="Sucursal" name={["inventories", "idSucursal"]}>
            <Select
              options={toOptions(sucursalesOptions, "id", "nombre")}
              loading={!sucursales}
              onChange={changeSucursal}
            ></Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InventoriesFilter;
