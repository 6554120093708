import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const TopicMenu = ({ topics = [], selectedKey, changeSelectedKey }) => {
  const menuItems = topics.map((itemMayor, index) => ({
    key: index,
    icon: itemMayor.icon,
    label: itemMayor.name,
    children: itemMayor.items.map((ItemMenor, i) => ({
      key: `${index}-${i}`,
      icon: ItemMenor.icon,
      label: (
        <Link
          to={ItemMenor.path}
          onClick={() => changeSelectedKey(`${index}-${i}`)}
        >
          {ItemMenor.name}
        </Link>
      ),
    })),
  }));

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[selectedKey]}
      items={menuItems}
    />
  );
};

export default TopicMenu;
