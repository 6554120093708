import React, { useRef, useState } from "react";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import ApiInventarioProductos from "../../../../../js/api/global/InventarioProductos";
import { formatCurrencyCLP } from "../../../../utils/formatPrices";
import { DollarTwoTone, ProfileTwoTone } from "@ant-design/icons";

const { Item } = Form;
const InventoriesProductEdit = ({
  isVisible,
  setIsVisible,
  productSelected,
  setProductSelected,
  isProductUpdated,
  setIsProductUpdated,
}) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const { idProductoSucursal, productoSucursal, idInventario } = productSelected;

  const submitForm = (data) => {
    setLoading(true);

    const productToEdit = {
      idProductoSucursal,
      idInventario,
      cantidad: parseInt(data.product.cantidad, 10),
      cantidadTeorica: parseInt(data.product.cantidadTeorica, 10),
      precioUnitario: parseInt(
        data.product.precioUnitario.replace(/\D/g, ""),
        10
      ),
    };

    ApiInventarioProductos.PostInventarioProducto(productToEdit, true).then(
      (resp) => {
        if (resp.success) {
          setLoading(false);
          setIsProductUpdated(!isProductUpdated);
          openNotification(
            "s",
            "Guardado exitoso",
            "Se ha guardado correctamente"
          );
          setProductSelected(null);
        } else {
          setLoading(false);
          openNotification("e", "Error de guardado", resp.message);
        }
      }
    );
  };
  const closeModal = () => {
    setIsVisible(false);
    setProductSelected(null);
  };

  const rules = {
    required: [{ required: true, message: "Este campo es obligatorio" }],
    number: [
      { required: true, message: "Este campo es obligatorio" },
      { pattern: /^[0-9]+$/, message: "Solo se permiten números" },
    ],
    maxLength: (max) => ({
      max,
      message: `Debe tener un máximo de ${max} caracteres`,
    }),
  };

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedValue = value ? formatCurrencyCLP(value, "decimal") : "";

    // Actualiza el valor formateado en el campo del formulario
    formRef.current.setFieldsValue({
      product: {
        ...formRef.current.getFieldValue("product"),
        precioUnitario: formattedValue,
      },
    });
  };

  return (
    <Modal
      title="Editar Producto"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <div>
        <h6>{productoSucursal}</h6>
      </div>
      <Form
        name="editInventoriesProduct"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
        initialValues={{
          product: {
            cantidad: productSelected.cantidad?.toString() || "",
            cantidadTeorica: productSelected.cantidadTeorica?.toString() || "",
          },
        }}
        onValuesChange={(_, allValues) => {
          formRef.current.setFieldsValue(allValues);
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Item
              name={["product", "precioUnitario"]}
              label="Precio unitario"
              initialValue={formatCurrencyCLP(productSelected.precioUnitario)}
              rules={[...rules.required]}
            >
              <Input
                maxLength={25}
                onChange={handlePriceChange}
                prefix={<DollarTwoTone />}
              />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["product", "cantidadTeorica"]}
              label="Cantidad teórica"
              initialValue={productSelected.cantidadTeorica}
              rules={[...rules.number, rules.maxLength(8)]}
            >
              <Input maxLength={8} prefix={<ProfileTwoTone />} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["product", "cantidad"]}
              label="Cantidad física"
              initialValue={productSelected.cantidad}
              rules={[...rules.number, rules.maxLength(8)]}
            >
              <Input maxLength={8} prefix={<ProfileTwoTone />} />
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InventoriesProductEdit;
