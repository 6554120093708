import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col, Upload } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { toOptions } from "../../../../../js/helpers/helpers";
import { InventoriesContext } from "../../../../../js/app/context/InventoriesContext";
import ApiInventarios from "../../../../../js/api/global/Inventarios";
import { UploadOutlined } from "@ant-design/icons";
import { Divider } from "antd";

const { Item } = Form;

const InventoriesInsert = () => {
  const [loading, setLoading] = useState(false);

  const { parametersData, setInventories, idSucursalSelected } =
    useContext(InventoriesContext);

  const { usuarios } = parametersData;

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    const cargaMaestra =
      data.inventories.file && data.inventories.file[0].originFileObj;
    const newInventory = {
      Comentario: data.inventories.comentario,
      IdSucursal: idSucursalSelected,
      Usuarios: data.inventories.usuarios,
    };

    const promise =
      typeof cargaMaestra === undefined
        ? ApiInventarios.PostInventario(newInventory)
        : ApiInventarios.PostInventario(newInventory, cargaMaestra);

    promise.then((resp) => {
      setLoading(false);
      if (resp.success) {
        setInventories(resp.dataList);

        openNotification(
          "s",
          "Inserción correcta",
          "Inventario ingresado y dejado en estado pendiente"
        );
        formRef.current.resetFields();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const filterOption = (inputValue, option) =>
    option.label.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >=
    0;

  return (
    <>
      <Form
        name="addInventory"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col md={12} xs={24}>
            <Item
              label="Usuarios"
              name={["inventories", "usuarios"]}
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                mode="multiple"
                placeholder="Selecciona usuarios"
                maxTagCount="responsive"
                options={toOptions(
                  usuarios.filter((x) => x.habilitado),
                  "id",
                  "nombre"
                )}
                loading={!usuarios}
                filterOption={filterOption}
              ></Select>
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              name={["inventories", "comentario"]}
              label="Comentario (Opcional)"
              initialValue=""
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              name={["inventories", "file"]}
              label="Inventario teórico"
              getValueFromEvent={normFile}
              valuePropName="FileList"
            >
              <Upload
                accept=".csv"
                multiple={false}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Cargar archivo .csv</Button>
              </Upload>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider dashed />
    </>
  );
};

export default InventoriesInsert;
