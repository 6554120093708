import { IAxios } from "../conf";

const baseEndpoint = "Empresas/";

const GetEmpresaList = async (all = true) => {
  const endpoint = baseEndpoint + "List/" + (all ? "true" : "");
  return await IAxios.get(endpoint);
};

const GetEmpresa = async (id) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.get(endpoint);
};

const PutEmpresa = async (id, empresa) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.put(endpoint, empresa);
};

const PostEmpresa = async (empresa) => {
  return await IAxios.post(baseEndpoint, empresa);
};

const ApiEmpresas = {
  GetEmpresaList,
  GetEmpresa,
  PutEmpresa,
  PostEmpresa,
};

export default ApiEmpresas;
