import React from "react";
import { Form, Col, Tabs } from "antd";

import MasterLoadsManualLoads from "./MasterLoadsManualLoads";
import MasterLoadsMassiveLoads from "./MasterLoadsMassiveLoads";

const { Item } = Form;

const MasterLoadsInsert = () => {
  const items = [
    {
      key: "1",
      label: "Carga Masiva",
      children: (
        <Col md={12} xs={24}>
          <MasterLoadsMassiveLoads />
        </Col>
      ),
    },
    {
      key: "2",
      label: "Carga Individual",
      children: (
        <Col md={24} xs={24}>
          <Item label="">
            <MasterLoadsManualLoads />
          </Item>
        </Col>
      ),
    },
  ];

  return (
    <>
      <div className="pages-container">
        <Tabs
          defaultActiveKey="1"
          tabPosition="top"
          type="card"
          className="ant-tabs-bar"
          animated={{ inkBar: true, tabPane: true }}
          items={items}
        />
      </div>
    </>
  );
};

export default MasterLoadsInsert;
