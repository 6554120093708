import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ConfigProvider } from "antd";
import es from "antd/lib/locale/es_ES";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <ConfigProvider locale={es}>
    <App />
  </ConfigProvider>,
  rootElement
);

registerServiceWorker();
