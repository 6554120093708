import { IAxios } from "../conf";

const baseEndpoint = "Usuarios/";

const GetUsuarioList = async (all = true) => {
  const endpoint = baseEndpoint + "List/" + (all? "true": "");

  return await IAxios.get(endpoint);
};

const GetUsuariosContador = async () => {
    const endpoint = baseEndpoint + "Contador/";
  
    return await IAxios.get(endpoint);
  };

const GetUsuario = async (id) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.get(endpoint);
};

const PutUsuario = async (id, usuario) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.put(endpoint, usuario);
};

const ChangePassword = async (id, usuario) => {
  const endpoint = `${baseEndpoint}changePassword/${id}`;

  return await IAxios.put(endpoint, usuario);
};

const PostUsuario = async (usuario) => {
  return await IAxios.post(baseEndpoint, usuario);
};

const ApiUsuarios = {
  GetUsuarioList,
  GetUsuariosContador,
  GetUsuario,
  PutUsuario,
  ChangePassword,
  PostUsuario,
};

export default ApiUsuarios;
