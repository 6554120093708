import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col, Modal, Upload } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { toOptions } from "../../../../../js/helpers/helpers";
import { InventoriesContext } from "../../../../../js/app/context/InventoriesContext";
import ApiInventarios from "../../../../../js/api/global/Inventarios";
import { UploadOutlined } from "@ant-design/icons";

const { Item } = Form;
const InventoriesEdit = ({
  isVisible,
  setIsVisible,
  selected,
  setSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const { parametersData, setInventories, idSucursalSelected } =
    useContext(InventoriesContext);

  const { usuarios } = parametersData;

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    const all = !idSucursalSelected;
    const cargaMaestra =
      data.inventories.file && data.inventories.file.length > 0
        ? data.inventories.file[0].originFileObj
        : undefined;

    const apiCall = cargaMaestra
      ? ApiInventarios.PutInventario(
          selected.id,
          all,
          data.inventories,
          cargaMaestra
        )
      : ApiInventarios.PutInventario(selected.id, all, data.inventories);

    apiCall.then((resp) => {
      setLoading(false);
      if (resp.success) {
        setInventories(resp.dataList);

        openNotification(
          "s",
          "Actualización correcta",
          "Datos de inventario actualizado"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };
  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const filterOption = (inputValue, option) =>
    option.label.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >=
    0;

  return (
    <Modal
      title="Editar inventario"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editInventory"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Item
          name={["inventories", "idSucursal"]}
          initialValue={selected.idSucursal}
          hidden={true}
        >
          <Input maxLength={25} />
        </Item>
        <Row gutter={[24, 16]}>
          <Col md={12} xs={24}>
            <Item
              label="Usuarios"
              name={["inventories", "usuarios"]}
              rules={[{ required: true }]}
              initialValue={selected.usuarios}
            >
              <Select
                allowClear
                mode="multiple"
                placeholder="Selecciona usuarios"
                maxTagCount="responsive"
                options={toOptions(
                  usuarios.filter((x) => x.habilitado),
                  "id",
                  "nombre"
                )}
                loading={!usuarios}
                filterOption={filterOption}
              ></Select>
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              name={["inventories", "comentario"]}
              label="Comentario (Opcional)"
              initialValue={selected.comentario}
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              label="Estado"
              name={["inventories", "idEstadoInventario"]}
              rules={[{ required: true }]}
              initialValue={selected.idEstadoInventario}
            >
              <Select
                placeholder="Selecciona un estado"
                options={[
                  {
                    label: "En curso",
                    value: 1,
                  },
                  {
                    label: "Finalizado",
                    value: 2,
                  },
                  {
                    label: "Pendiente",
                    value: 3,
                  },
                ]}
              ></Select>
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              name={["inventories", "file"]}
              label="Inventario teórico"
              getValueFromEvent={normFile}
              valuePropName="FileList"
            >
              <Upload
                accept=".csv"
                multiple={false}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Cargar archivo .csv</Button>
              </Upload>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InventoriesEdit;
