import React, { useContext, useRef, useState, useEffect } from "react";
import { Button, Form, Input, Row, Select, Col } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { toOptions } from "../../../../../js/helpers/helpers";
import { MasterLoadsContext } from "../../../../../js/app/context/MasterLoadsContext";
import ApiProductoSucursals from "../../../../../js/api/global/ProductoSucursals";

const { Item } = Form;

const MasterLoadsManualLoads = () => {
  const {
    MasterLoadCategories,
    idEmpresaSelected,
    setMasterLoad,
    setMasterLoadCategories,
  } = useContext(MasterLoadsContext);

  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const other = {
    value: "OTRO",
    text: "SELECCIONAR OTRO...",
  };

  const submitForm = (data) => {
    setLoading(true);
    const productoSucursal = { ...data.ProductoSucursal };

    if (productoSucursal.categoria === "OTRO")
      productoSucursal.categoria = productoSucursal.otraCategoria;

    let dataToSave = { ...productoSucursal, idEmpresa: idEmpresaSelected };

    ApiProductoSucursals.PostProductoSucursalByCompanyUnit(dataToSave).then(
      (resp) => {
        setLoading(false);

        if (resp.success) {
          //Se actualiza el listado de la tabla
          setMasterLoad(resp.dataList);

          //Se actualiza listado de categorias en base a lo retornado en la APÍ
          let categories = resp.dataList.map((x) => x.categoria);
          categories = [...new Set(categories)];

          const categoriesObj = categories.map((x) => ({
            text: x,
            value: x,
          }));

          setShowOtherCategory(false);
          setMasterLoadCategories(categoriesObj);
          
          formRef.current.resetFields();

          openNotification(
            "s",
            "Inserción correcta",
            "Se ha ingresado la carga manual"
          );
        }
        else {
          if(resp.subStatus === "Warning")
          {
            openNotification("w", "Atención", resp.message);
          }
          else
          {
            openNotification("e", "Error de inserción", resp.message);
          }
          
        }
      }
    );
  };

  const changeCategory = (value) => setShowOtherCategory(value === "OTRO");

  useEffect(() => {
    setShowOtherCategory(false);
    formRef.current.resetFields();
  }, [idEmpresaSelected]);

  return (
    <Form
      name="addManualLoad"
      layout="vertical"
      requiredMark={false}
      ref={formRef}
      onFinish={submitForm}
    >
      <Row gutter={[24, 16]}>
        <Col md={12} xs={24}>
          <Item
            label="Codigo Maestro"
            name={["ProductoSucursal", "codigoMaestra"]}
            rules={[{ required: true }]}
            initialValue=""
          >
            <Input maxLength={35} />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            label="Codigo de barras"
            name={["ProductoSucursal", "codigoBarra"]}
            rules={[{ required: true }]}
            initialValue=""
          >
            <Input maxLength={35} />
          </Item>
        </Col>
        <Col md={24} xs={24}>
          <Item
            label="Producto"
            name={["ProductoSucursal", "descripcion"]}
            rules={[{ required: true }]}
            initialValue=""
          >
            <Input maxLength={100} />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            label="Categoría"
            name={["ProductoSucursal", "categoria"]}
            rules={[{ required: true }]}
          >
            <Select
              options={toOptions(
                [...MasterLoadCategories, other],
                "value",
                "text"
              )}
              loading={!MasterLoadCategories}
              onChange={changeCategory}
            ></Select>
          </Item>
        </Col>

        {!!showOtherCategory && (
          <Col md={12} xs={24}>
            <Item
              label="Otra categoria"
              name={["ProductoSucursal", "otraCategoria"]}
              rules={[{ required: true }]}
              initialValue=""
            >
              <Input maxLength={35} />
            </Item>
          </Col>
        )}
      </Row>
      <Row justify="center">
        <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Ingresar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default MasterLoadsManualLoads;
