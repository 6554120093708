import helperSession from "../../helpers/helpersSession";

export const handlerLogout = () => {
  helperSession.SetAccount("token", "");
  helperSession.SetAccount("rol", "");
  helperSession.SetAccount("nick", "");
  helperSession.SetAccount("nombre", "");

  window.location = "/login";
};
