import { openNotification } from "../../components/controls/Notifications";

export const servercodeerrormessages = (status) => {
  switch (status) {
    case 403:
      openNotification(
        "e",
        "Sin autorización",
        "Usario intenta ingresar a un módulo sin autorización"
      );
      break;

    default:
      if (status >= 200 && status < 300) break;
      openNotification(
        "e",
        "Error interno",
        "Ha ocurrido un error interno no controlado"
      );
      break;
  }
};
