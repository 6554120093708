import { IAxios } from "../conf";

const baseEndpoint = "TipoUsuarios/";

const GetTipoUsuarioList = async () => {
  return await IAxios.get(baseEndpoint);
};

const ApiTipoUsuarios = {
  GetTipoUsuarioList,
};

export default ApiTipoUsuarios;
