import { Col, Row } from "antd";
import React from "react";
import DateTable from "../../../../controls/DateTable";
import { Link } from "react-router-dom";
import { BankOutlined, ShopOutlined } from "@ant-design/icons";

const SelectInventorieList = ({ loading, inventoriesInCourse }) => {
  const columns = [
    {
      title: "Inventario",
      key: "correlativo",
      render: (inventorie) => (
        <Link to={`/countinv/${inventorie.id}`}  style={{ color: "black" }}>
          <Row
            gutter={[24, 16]}
            style={{ cursor: "pointer" }}
          >
            <Col xs={5} style={{ textAlign: "center" }}>
              <br />
              <h4>{inventorie.correlativo}</h4>
            </Col>
            <Col xs={12}>
              <b>
                <BankOutlined /> {inventorie.empresa}
              </b>
              <br />
              <span>
                <ShopOutlined /> {inventorie.sucursal}
              </span>
              <br />
              <cite style={{ fontSize: "10px" }}>
                {inventorie.fechaFormateada}
              </cite>
            </Col>
          </Row>
        </Link>
      ),
    },
  ];
  return (
    <>
      <DateTable
        data={inventoriesInCourse}
        columns={columns}
        keyValue="id"
        loading={loading}
      />
    </>
  );
};

export default SelectInventorieList;
