import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiInventarios from "../../../../js/api/global/Inventarios";
import { openNotification } from "../../../controls/Notifications";
import CountInventorieSearch from "./partial/CountInventorieSearch";
import CountInventorieHeader from "./partial/CountInventorieHeader";

const CountInventorie = () => {
  const { id } = useParams();

  const [inventorie, setInventorie] = useState(null);

  useEffect(() => {
    ApiInventarios.GetInventarioSelected(id).then((resp) => {
        if (resp.success) {
            setInventorie(resp.data);
        } else {
          openNotification("e", "Error de búsqueda", resp.message);
        }
      });
  }, [id]);

  return (
    <div className="container-xl">
      <h3>Conteo de productos</h3>
      <h6>Haz click sobre el inventario que desees contar</h6>
      <Divider dashed />
      <div>
        <CountInventorieHeader  inventorie={inventorie} />
        <CountInventorieSearch inventorie={inventorie}/>
      </div>
    </div>
  );
};

export default CountInventorie;
