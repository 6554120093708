import React from "react";
import {
  CheckSquareOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";

export const navigationSupervisor = [
  {
    name: "Inventarios",
    icon: <ContainerOutlined />,
    items: [
      {
        name: "Inventarios",
        icon: <UnorderedListOutlined />,
        path: "/inventories",
      },
      { name: "Carga maestra", icon: <UploadOutlined />, path: "/masterload" },
      {
        name: "Conteo productos",
        icon: <CheckSquareOutlined />,
        path: "/selectinv",
      },
    ],
  },
];
