import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import ApiEmpresas from "../../../../js/api/global/Empresas";
import { CompaniesContext } from "../../../../js/app/context/CompaniesContext";
import { openNotification } from "../../../controls/Notifications";
import CompaniesInsert from "./partial/CompaniesInsert";
import CompaniesList from "./partial/CompaniesList";

const Companies = () => {
  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiEmpresas.GetEmpresaList(true),
    ]).then((res) => {
      setLoading(false);
      const empresas = res[0];

      if (
        empresas.success 
      ) {
        
        setCompanies(empresas.dataList);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  return (
    <div className="container-xl">
      <h3>Mantenedor empresas</h3>
      <Divider dashed />
      <div>
        <CompaniesContext.Provider
          value={{ companies, setCompanies, loading }}
        >
          <CompaniesInsert />
          <Divider dashed />
          <CompaniesList />
        </CompaniesContext.Provider>
      </div>
    </div>
  );
};

export default Companies;
