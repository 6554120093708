import React from "react";
import { Drawer, Button, Menu } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import "./NavBar.css";
import logoBlanco from "../../../img/Logo.png";
import { Link } from "react-router-dom";
import helperSession from "../../../js/helpers/helpersSession";
import { handlerLogout } from "../../../js/app/auth/logout";

const NavBar = ({ menu, visible, setVisible }) => {
  const menuItems = [
    {
      key: "SubMenu",
      icon: <UserOutlined />,
      label: helperSession.GetAccount("nick"),
      children: [
        {
          key: "setting:1",
          label: "Cerrar sesión",
          onClick: handlerLogout,
        },
      ],
    },
  ];

  return (
    <nav className="navbar">
      <Button
        className="menu btn-menu-outline"
        type="default"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title="Menú"
        className="dark-mode-aux"
        placement="left"
        onClose={() => setVisible(false)}
        open={visible}
      >
        {menu}
      </Drawer>
      <Link to="/home">
        <img src={logoBlanco} className="logo" alt="logo" />
      </Link>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Menu
          theme="dark"
          style={{
            width: 250,
          }}
          mode="horizontal"
          items={menuItems}
        />
      </div>
    </nav>
  );
};

export default NavBar;
