import { apiUrl } from "../config/environment";
import axios from "axios";
import helperSession from "../helpers/helpersSession";
import { openNotification } from "../../components/controls/Notifications";
import { servercodeerrormessages } from "../config/servercodeerrormessages";

//** Conf **/

//Token
const sessionToken = helperSession.GetAccount("token");

//API URL
const baseURL = `${apiUrl()}/api/`;
//TokenHeaders
const baseTokenHeaders = {
  Authorization: `Bearer ${sessionToken}`,
};

//#region "Fetch"

//AppJsonTokenHeaders
const baseFetchJsonTokenHeaders = {
  "Content-Type": "application/json",
  ...baseTokenHeaders,
};

//AppFormDataTokenHeaders
const baseFetchFormDataTokenHeaders = {
  ...baseTokenHeaders,
};

//#endregion "End fetch"

//#region AXIOS

//AxiosHeaderBase
//JSON
const baseAxiosJsonHeaders = {
  headers: {
    ...baseTokenHeaders,
  },
};
//FORMDATA
const baseAxiosFormDataHeaders = {
  headers: {
    ...baseTokenHeaders,
    "Content-Type": "multipart/form-data",
  },
};
//#endregion AXIOS

//** Exports variables **/
export const token = `Bearer ${sessionToken}`;
export const url = baseURL;

//Fetch Headers
export const FetchJsonTokenHeaders = baseFetchJsonTokenHeaders;
export const FetchFormDataTokenHeaders = baseFetchFormDataTokenHeaders;

//Axios Headers
export const AxiosJsonHeaders = baseAxiosJsonHeaders;
export const AxiosFormDataHeaders = baseAxiosFormDataHeaders;

//Only Token Header
export const TokenHeaders = baseTokenHeaders;

//** Exports Functions **/
export const getUrl = (inUrl) => `${baseURL}${inUrl}`;

export const InjectTokenHeader = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const IAxios = axios.create({
  baseURL,
  validateStatus: (status) => {
    servercodeerrormessages(status);
    return status >= 200 && status < 300;
  },
  ...baseAxiosJsonHeaders,
});

IAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      openNotification(
        "e",
        "Problemas de conexión",
        "No existe conexión con el servidor"
      );
    }

    return Promise.reject(error);
  }
);

export const IAxiosFileDownload = axios.create({
  baseURL,
  validateStatus: (status) => {
    servercodeerrormessages(status);
    return status >= 200 && status < 300;
  },
  ...baseAxiosJsonHeaders,
  responseType: "blob"
});

IAxiosFileDownload.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      openNotification(
        "e",
        "Problemas de conexión",
        "No existe conexión con el servidor"
      );
    }

    return Promise.reject(error);
  }
);

export const IAxiosFormData = axios.create({
  baseURL,
  ...baseAxiosFormDataHeaders,
  validateStatus: (status) => {
    servercodeerrormessages(status);
    return status >= 200 && status < 300;
  },
});

IAxiosFormData.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      openNotification(
        "e",
        "Problemas de conexión",
        "No existe conexión con el servidor"
      );
    }
    return Promise.reject(error);
  }
);
