import { Button } from "antd";
import React, { useContext, useState } from "react";
import ApiSucursals from "../../../../../js/api/global/Sucursals";
import { BranchOfficesContext } from "../../../../../js/app/context/BranchOfficesContext";
import { helperStatus } from "../../../../../js/helpers/tableHelpers";
import DateTable from "../../../../controls/DateTable";
import { openNotification } from "../../../../controls/Notifications";
import BranchOfficesEdit from "./BranchOfficesEdit";

const BranchOfficesList = () => {
  const { branchOffices, loading } = useContext(BranchOfficesContext);

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState(null);

  const editBranchOffices = (branchOffice) => {
    ApiSucursals.GetSucursal(branchOffice.id).then((resp) => {
      if (resp.success) {
        setBranchOfficeSelected(resp.data);
        setIsEditVisible(true);
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      search: true,
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
      search: true,
    },
    {
      title: "Comuna",
      dataIndex: "comuna",
      key: "comuna",
      search: true,
    },
    helperStatus,
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (branchOffice) => (
        <Button type="primary" onClick={() => editBranchOffices(branchOffice)}>
          Editar
        </Button>
      ),
    },
  ];

  return (
    <>
      <DateTable
        data={branchOffices}
        columns={columns}
        keyValue="id"
        loading={loading}
      />
      {branchOfficeSelected && (
        <BranchOfficesEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          selected={branchOfficeSelected}
          setSelected={setBranchOfficeSelected}
        />
      )}
    </>
  );
};

export default BranchOfficesList;
