import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col, Modal } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { UsersContext } from "../../../../../js/app/context/UsersContext";
import { toOptions } from "../../../../../js/helpers/helpers";
import { usersInsertRules } from "../../../../../js/validations/admin/UsersInsertRules";
import ApiUsuarios from "../../../../../js/api/global/Usuarios";

const { Item } = Form;
const UsersEdit = ({ isVisible, setIsVisible, selected, setSelected }) => {
  const [loading, setLoading] = useState(false);

  const { parametersData, setUsers, users } = useContext(UsersContext);

  const { tipoUsuarios } = parametersData;

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiUsuarios.PutUsuario(selected.id, data.user).then((resp) => {
      setLoading(false);
      if (resp.success) {
        const newUsers = users.filter((x) => x.id !== selected.id);

        setUsers([...newUsers, resp.data]);
        openNotification(
          "s",
          "Actualización correcta",
          "Se ha actualizado el usuario correctamente"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de actualización", resp.message);
      }
    });
  };

  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  return (
    <Modal
      title="Editar usuario"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editUser"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Item
              name={["user", "nombre"]}
              label="Nombre"
              initialValue={selected.nombre}
              rules={usersInsertRules.nombre}
            >
              <Input maxLength={75} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["user", "nick"]}
              label="Nick"
              initialValue={selected.nick}
              rules={usersInsertRules.nick}
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["user", "telefono"]}
              label="Telefono"
              initialValue={selected.telefono}
              style={{ display: "block" }}
              rules={usersInsertRules.telefono}
            >
              <Input type="number" placeholder="933556689" />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["user", "correo"]}
              label="Correo"
              initialValue={selected.correo}
              rules={usersInsertRules.correo}
            >
              <Input maxLength={75} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["user", "idTipoUsuario"]}
              label="TipoUsuario"
              initialValue={selected.idTipoUsuario}
              rules={usersInsertRules.idTipoUsuario}
            >
              <Select
                options={toOptions(tipoUsuarios, "id", "descripcion")}
                loading={!tipoUsuarios}
              ></Select>
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["user", "habilitado"]}
              label="Estado"
              rules={usersInsertRules.habilitado}
              initialValue={selected.habilitado}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "Habilitada",
                  },
                  {
                    value: false,
                    label: "Deshabilitada",
                  },
                ]}
              ></Select>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UsersEdit;
