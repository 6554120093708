import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { UsersContext } from "../../../../../js/app/context/UsersContext";
import { usersInsertRules } from "../../../../../js/validations/admin/UsersInsertRules";
import ApiUsuarios from "../../../../../js/api/global/Usuarios";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Item } = Form;
const UsersChangePassword = ({
  isVisible,
  setIsVisible,
  selected,
  setSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const { setUsers, users } = useContext(UsersContext);

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiUsuarios.ChangePassword(selected.id, data.user).then((resp) => {
      setLoading(false);
      if (resp.success) {
        const newUsers = users.filter((x) => x.id !== selected.id);

        setUsers([...newUsers, resp.data]);
        openNotification(
          "s",
          "Actualización correcta",
          "Se ha actualizado la contraseña del usuario"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de actualización", resp.message);
      }
    });
  };

  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  return (
    <Modal
      title="Cambiar contraseña"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editPasswordUser"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col md={12} xs={24}>
            <Item
              name={["user", "password"]}
              label="Contraseña"
              hasFeedback
              rules={usersInsertRules.password}
            >
              <Input.Password
                maxLength={25}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Item>
          </Col>
          <Col md={12} xs={24}>
            <Item
              name={["user", "confirmPassword"]}
              label="Confirmar contraseña"
              hasFeedback
              rules={usersInsertRules.confirmPassword}
            >
              <Input.Password
                maxLength={25}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UsersChangePassword;
