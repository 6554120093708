import React from "react";
import { CheckSquareOutlined, ContainerOutlined } from "@ant-design/icons";

export const navigationContador = [
  {
    name: "Inventarios",
    icon: <ContainerOutlined />,
    items: [
      {
        name: "Conteo productos",
        icon: <CheckSquareOutlined />,
        path: "/selectinv",
      },
    ],
  },
];
