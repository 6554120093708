import React from "react";
import { Layout } from "antd";
import "./SideBar.css";
import helperSession from "../../../js/helpers/helpersSession";

const SideBar = ({ menu }) => {
  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="dark"
      collapsedWidth={0}
      trigger={null}
    >
      <br/>
      <span className="sidebar-user-name">
        <div>
          {helperSession.GetAccount("nombre")}
          <br/>
          <cite>
          {helperSession.GetAccount("rol")}
          </cite>
          
        </div>
      </span>
      <br /> <br />
      {menu}
    </Layout.Sider>
  );
};

export default SideBar;
