import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col, Modal } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { CompaniesContext } from "../../../../../js/app/context/CompaniesContext";
import ApiEmpresas from "../../../../../js/api/global/Empresas";

const { Item } = Form;
const CompaniesEdit = ({ isVisible, setIsVisible, selected, setSelected }) => {
  const [loading, setLoading] = useState(false);

  const { setCompanies, companies } = useContext(CompaniesContext);

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiEmpresas.PutEmpresa(selected.id, data.company).then((resp) => {
      setLoading(false);
      if (resp.success) {
        const newCompanies = companies.filter((x) => x.id !== selected.id);

        setCompanies([...newCompanies, resp.data]);
        openNotification(
          "s",
          "Actualización correcta",
          "Se ha actualizado la empresa correctamente"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de actualización", resp.message);
      }
    });
  };

  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  return (
    <Modal
      title="Editar empresa"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editCompany"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Item
              name={["company", "nombre"]}
              label="Nombre"
              rules={[{ required: true }]}
              initialValue={selected.nombre}
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["company", "habilitado"]}
              label="Estado"
              rules={[{ required: true }]}
              initialValue={selected.habilitado}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "Habilitada",
                  },
                  {
                    value: false,
                    label: "Deshabilitada",
                  },
                ]}
              ></Select>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompaniesEdit;
