export const formatCurrencyCLP = (value, style) => {
  if (isNaN(value)) {
    return "";
  }
  return new Intl.NumberFormat("es-CL", {
    style,
    currency: "CLP",
    minimumFractionDigits: 0,
  }).format(value);
};
