import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Col } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { CompaniesContext } from "../../../../../js/app/context/CompaniesContext";
import ApiEmpresas from "../../../../../js/api/global/Empresas";

const { Item } = Form;

const CompaniesInsert = () => {
  const [loading, setLoading] = useState(false);

  const { setCompanies, companies } = useContext(CompaniesContext);

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiEmpresas.PostEmpresa(data.company).then((resp) => {
      setLoading(false);
      if (resp.success) {
        setCompanies([...companies, resp.data]);

        openNotification(
          "s",
          "Inserción correcta",
          "Se ha ingresado la empresa correctamente"
        );
        formRef.current.resetFields();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  return (
    <Form
      name="addcompany"
      layout="vertical"
      requiredMark={false}
      ref={formRef}
      onFinish={submitForm}
    >
      <Row gutter={[24, 16]}>
        <Col md={24} xs={24}>
          <Item
            name={["company", "nombre"]}
            label="Nombre"
            rules={[{ required: true }]}
          >
            <Input maxLength={25} />
          </Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={12}  xs={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompaniesInsert;
