import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col, Modal } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { BranchOfficesContext } from "../../../../../js/app/context/BranchOfficesContext";
import { toOptions } from "../../../../../js/helpers/helpers";
import { branchOfficesInsertRules } from "../../../../../js/validations/admin/BranchOfficesInsertRules";
import ApiSucursals from "../../../../../js/api/global/Sucursals";

const { Item } = Form;
const BranchOfficesEdit = ({
  isVisible,
  setIsVisible,
  selected,
  setSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const [comunasOptions, setComunasOptions] = useState([]);

  const { parametersData, setBranchOffices, branchOffices } =
    useContext(BranchOfficesContext);

  const { regions, comunas, empresas } = parametersData;

  const formRef = useRef();

  useEffect(() => {
    const comunasFiltered =
      comunas.filter((x) => x.idRegion === selected.idRegion) ?? [];
    setComunasOptions(comunasFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = (data) => {
    setLoading(true);
    ApiSucursals.PutSucursal(selected.id, data.branchOffice).then((resp) => {
      setLoading(false);
      if (resp.success) {
        const newBranchOffices = branchOffices.filter(
          (x) => x.id !== selected.id
        );

        setBranchOffices([...newBranchOffices, resp.data]);
        openNotification(
          "s",
          "Actualización correcta",
          "Se ha actualizado la sucursal correctamente"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de actualización", resp.message);
      }
    });
  };

  const changeRegion = (value) => {
    const comunasFiltered = comunas.filter((x) => x.idRegion === value) ?? [];

    formRef.current.setFields([
      { name: ["branchOffice", "idComuna"], value: "" },
    ]);

    setComunasOptions(comunasFiltered);
  };

  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  return (
    <Modal
      title="Editar sucursal"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editBranchOffice"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Item
              name={["branchOffice", "IdEmpresa"]}
              label="Empresa"
              rules={branchOfficesInsertRules.idEmpresa}
              initialValue={selected.idEmpresa}
            >
              <Select
                options={toOptions(empresas, "id", "nombre")}
                loading={!empresas}
              ></Select>
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["branchOffice", "nombre"]}
              label="Nombre"
              rules={branchOfficesInsertRules.nombre}
              initialValue={selected.nombre}
            >
              <Input maxLength={25} />
            </Item>
          </Col>

          <Col xs={24}>
            <Item
              label="Region"
              name={["branchOffice", "idRegion"]}
              rules={branchOfficesInsertRules.region}
              initialValue={selected.idRegion}
            >
              <Select
                options={toOptions(regions, "id", "nombre")}
                loading={!regions}
                onChange={changeRegion}
              ></Select>
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["branchOffice", "idComuna"]}
              label="Comuna"
              rules={branchOfficesInsertRules.comuna}
              initialValue={selected.idComuna}
            >
              <Select
                options={toOptions(comunasOptions, "id", "nombre")}
                loading={!comunas}
              ></Select>
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["branchOffice", "habilitado"]}
              label="Estado"
              rules={branchOfficesInsertRules.habilitado}
              initialValue={selected.habilitado}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "Habilitada",
                  },
                  {
                    value: false,
                    label: "Deshabilitada",
                  },
                ]}
                loading={!comunas}
              ></Select>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BranchOfficesEdit;
