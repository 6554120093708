import ApiLogin from "../../api/membresia/Login.js";
import helperSession from "../../helpers/helpersSession.js";

export const MainLogin = (rut, password) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!helperSession.GetAccount("token")) {
      ApiLogin.login(rut, password)
        .then((data) => {
          if (data.success) {
            saveAuthData(data.data);
            resolve(true);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject({ message: "Error de conexión." });
        });
    } else {
      window.history.replaceState({}, document.title, window.location.pathname);
      resolve(true);
    }
  });
};

const saveAuthData = async (data) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  helperSession.SetAccount("token", data.token);
  helperSession.SetAccount("rol", data.rol);
  helperSession.SetAccount("nick", data.nick);
  helperSession.SetAccount("nombre", data.nombre);
  window.location = "/home";
};
