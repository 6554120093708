import React, { useState } from "react";
import { Layout } from "antd";
import NavBar from "./partial/NavBar";
import SideBar from "./partial/SideBar";
// import Menu from "./partial/Menu";
import "./LayoutPrivate.css";
import TopicMenu from "./partial/TopicMenu";
import { getNavigations } from "../../js/config/navigation/navigation";

const { Content, Footer } = Layout;

const LayoutPrivate = ({ children }) => {
  const [selectedKey, setSelectedKey] = useState("0");
  const [visibleMenu, setVisibleMenu] = useState(false);
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setVisibleMenu(false);
  };
  const Menu = (
    <TopicMenu
      topics={getNavigations()}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <div>
      <Layout>
        <SideBar menu={Menu} />
        <Layout>
          <NavBar
            menu={Menu}
            visible={visibleMenu}
            setVisible={setVisibleMenu}
          />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              paddingTop: 50,
              margin: "24px 16px 0px",
              minHeight: 300,
              overflow: "initial",
            }}
          >
            {children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Puzzle Data ©2021 Created by Ant UED
          </Footer>
        </Layout>
      </Layout>

      
    </div>
  );
};

export default LayoutPrivate;
