import { IAxios } from "../conf";

const baseEndpoint = "InventarioProductoes/";

const GetInventarioProductoSelected = async (idInventario, codigoBarra) => {
  const endpoint = baseEndpoint + idInventario + "/" + codigoBarra;

  return await IAxios.get(endpoint);
};

const GetInventarioProductoSelectedById = async (idInventario) => {
  const endpoint = baseEndpoint + idInventario;

  return await IAxios.get(endpoint);
};

const PostInventarioProducto = async (inventarioProducto, isEdit = false) => {
  const endpoint = isEdit ? `${baseEndpoint}?isEdit=true` : baseEndpoint;
  return await IAxios.post(endpoint, inventarioProducto);
};

const ApiInventarioProductos = {
  GetInventarioProductoSelected,
  GetInventarioProductoSelectedById,
  PostInventarioProducto,
};

export default ApiInventarioProductos;
