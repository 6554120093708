import React from 'react';
import helperSession from '../../js/helpers/helpersSession';

const Home = () => {
    return (
        <div>
            Bienvenido {helperSession.GetAccount("nombre")}.
        </div>
    );
}

export default Home;
