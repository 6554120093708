// Definiciones de ambientes en base a los entornos ejecutados
// incluye las url de multi asociadas a cada ambiente

const envURLs = {
    bkndLocalhost: "https://localhost:44359",
    bkndProduction: "https://controlledos.cl",
    bkndQa: "https://inventariogasolinera.runasp.net",
    invalidEnvironment: "revisar.url.environment",
  };
  const env = () => {
    if (process.env.REACT_APP_SUBENVIRONMENT === "production") {
      envURLs.bkndLocalhost = envURLs.bkndProduction;
    }
    if (process.env.REACT_APP_SUBENVIRONMENT === "qa") {
      envURLs.bkndLocalhost = envURLs.bkndQa;
    }
    let url, front;
    if (process.env.NODE_ENV === "production") {
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        url = envURLs.bkndProduction;
      } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
        url = envURLs.bkndQa;
      } else {
        url = envURLs.invalidEnvironment;
      }
    } else if (process.env.NODE_ENV === "development") {
      url = envURLs.bkndLocalhost;
    } else {
      url = envURLs.invalidEnvironment;
    }
    return { url, front };
  };
  
  //Corresponde a la url de la API (No incluye /api/)
  export const apiUrl = () => env().url;
  