import { Button, Dropdown, Tooltip } from "antd";
import fileDownload from "js-file-download";
import React, { useContext, useState } from "react";
import ApiInventarios from "../../../../../js/api/global/Inventarios";
import { InventoriesContext } from "../../../../../js/app/context/InventoriesContext";
import DateTable from "../../../../controls/DateTable";
import { openNotification } from "../../../../controls/Notifications";
import InventoriesEdit from "./InventoriesEdit";
import { DownOutlined } from "@ant-design/icons";

const InventoriesList = ({ setIsInventoryView, setInventorySelected }) => {
  const { inventories, loading } = useContext(InventoriesContext);

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [inventorieSelected, setInventorieSelected] = useState(null);

  const editInventories = (inventorie) => {
    ApiInventarios.GetInventarioSelected(inventorie.id).then((resp) => {
      if (resp.success) {
        setInventorieSelected(resp.data);
        setIsEditVisible(true);
      } else {
        openNotification("e", "Error de solicitud", resp.message);
      }
    });
  };

  const downloadInventories = (inventorie) => {
    ApiInventarios.GetInventarioToDownload(inventorie.id).then((resp) => {
      const { empresa, sucursal, correlativo, fechaFormateada } = inventorie;
      const fileName = `Inventario ${empresa} (${sucursal}) N${correlativo} - ${fechaFormateada}.csv`;
      fileDownload(resp, fileName);
    });
  };

  const viewInventorie = (inventorie) => {
    setIsInventoryView(true);
    setInventorySelected(inventorie);
  };

  const handleMenuClick = (key, inventorie) => {
    switch (key) {
      case "edit":
        editInventories(inventorie);
        break;
      case "download":
        downloadInventories(inventorie);
        break;
      case "view":
        viewInventorie(inventorie);
        break;
      default:
        break;
    }
  };

  const getMenuItems = (inventorie) => [
    {
      label: "Editar",
      key: "edit",
      onClick: () => handleMenuClick("edit", inventorie),
    },
    {
      label: "Descargar",
      key: "download",
      onClick: () => handleMenuClick("download", inventorie),
    },
    {
      label: "Ver inventario",
      key: "view",
      onClick: () => handleMenuClick("view", inventorie),
    },
  ];

  const columns = [
    {
      title: "Correlativo",
      dataIndex: "correlativo",
      key: "correlativo",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.correlativo - b.correlativo,
      search: true,
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
      search: true,
    },
    {
      title: "Sucursal",
      dataIndex: "sucursal",
      key: "sucursal",
      search: true,
    },
    {
      title: "Comentario",
      dataIndex: "comentario",
      key: "comentario",
      search: true,
    },
    {
      title: "Fecha creación",
      dataIndex: "fechaFormateada",
      key: "fechaFormateada",
      search: true,
    },
    {
      title: "Estado",
      dataIndex: "estadoInventario",
      key: "estadoInventario",
      onFilter: (value, record) => record.estadoInventario.indexOf(value) === 0,
      filters: [
        {
          text: "En curso",
          value: "En curso",
        },
        {
          text: "Finalizado",
          value: "Finalizado",
        },
        {
          text: "Pendiente",
          value: "Pendiente",
        },
      ],
    },

    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (inventorie) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Dropdown
            menu={{ items: getMenuItems(inventorie) }}
            trigger={["click"]}
          >
            <Tooltip title="Opciones">
              <Button
                type="text"
                icon={<DownOutlined />}
                aria-label="Más opciones"
              />
            </Tooltip>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <>
      <DateTable
        data={inventories}
        columns={columns}
        keyValue="id"
        loading={loading}
      />
      {inventorieSelected && (
        <InventoriesEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          selected={inventorieSelected}
          setSelected={setInventorieSelected}
        />
      )}
    </>
  );
};

export default InventoriesList;
