import React, { useState, useRef, useContext } from "react";
import { Button, Form, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ApiProductoSucursals from "../../../../../js/api/global/ProductoSucursals";
import { MasterLoadsContext } from "../../../../../js/app/context/MasterLoadsContext";

import { openNotification } from "../../../../controls/Notifications";
const { Item } = Form;

const MasterLoadsMassiveLoads = () => {
    const { idEmpresaSelected, setMasterLoad, setMasterLoadCategories } =
        useContext(MasterLoadsContext);

    const [loading, setLoading] = useState(false);

    const formRef = useRef();
    const submitForm = (data) => {
        setLoading(true);
        const cargaMaestra = data.masterload.file[0].originFileObj;

        ApiProductoSucursals.PostProductoSucursalByCompany(
            idEmpresaSelected,
            cargaMaestra
        ).then((resp) => {
            setLoading(false);

            if (resp.success) {
                //Se actualiza el listado de la tabla
                setMasterLoad(resp.dataList);

                //Se actualiza listado de categorias en base a lo retornado en la APÍ
                let categories = resp.dataList.map((x) => x.categoria);
                categories = [...new Set(categories)];

                const categoriesObj = categories.map((x) => ({
                    text: x,
                    value: x,
                }));

                setMasterLoadCategories(categoriesObj);

                openNotification(
                    "s",
                    "Inserción correcta",
                    "Se ha ingresado la nueva carga maestra"
                );
                //Se resetea el formulario
                formRef.current.resetFields();
            } else {
                openNotification("e", "Error de inserción", resp.message);
            }
        });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (

        <Form
            name="addInventory"
            layout="vertical"
            requiredMark={false}
            ref={formRef}
            onFinish={submitForm}
        >
            <Item
                name={["masterload", "file"]}
                getValueFromEvent={normFile}
                valuePropName="FileList"
                rules={[{ required: true }]}
            >
                <Upload
                    accept=".csv"
                    multiple={false}
                    beforeUpload={() => false}
                    listType="picture"
                >
                    <Button icon={<UploadOutlined />}>Selecionar carga maestra</Button>
                </Upload>
            </Item>
            <Item label="">
                <Button type="primary" htmlType="submit" loading={loading}>
                    Cargar
                </Button>
            </Item>
        </Form>
    );
};

export default MasterLoadsMassiveLoads;
