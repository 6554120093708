import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import ApiInventarios from "../../../../js/api/global/Inventarios";
import { openNotification } from "../../../controls/Notifications";
import SelectInventorieList from "./partial/SelectInventorieList";

const SelectInventorie = () => {
  const [loading, setLoading] = useState(false);
  const [inventoriesInCourse, setInventoriesInCourse] = useState([]);

  useEffect(() => {
    setLoading(true);
    ApiInventarios.GetInventarioInCourse().then((resp) => {
      setLoading(false);
      if (resp.success) {
        setInventoriesInCourse(resp.dataList);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  return (
    <div className="container-xl">
      <h3>Seleccionar inventario</h3>
      <h6>Haz click sobre el inventario que desees contar</h6>
      <Divider dashed />
      <div>
        <SelectInventorieList
          loading={loading}
          inventoriesInCourse={inventoriesInCourse}
        />
      </div>
    </div>
  );
};

export default SelectInventorie;
