import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Col, Modal, Select } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import ApiProductoSucursals from "../../../../../js/api/global/ProductoSucursals";
import { MasterLoadsContext } from "../../../../../js/app/context/MasterLoadsContext";
import { toOptions } from "../../../../../js/helpers/helpers";

const { Item } = Form;
const MasterLoadsProductEdit = ({
  isVisible,
  setIsVisible,
  selected,
  setSelected,
}) => {
  const [loading, setLoading] = useState(false);

  const { setMasterLoad, idEmpresaSelected, MasterLoadCategories } =
    useContext(MasterLoadsContext);

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);

    const editProductoSucursal = {
      ...data.masterload,
      idEmpresa: idEmpresaSelected,
    };

    ApiProductoSucursals.PutProductoSucursal(
      selected.id,
      editProductoSucursal
    ).then((resp) => {
      setLoading(false);
      if (resp.success) {
        setMasterLoad(resp.dataList);

        openNotification(
          "s",
          "Actualización correcta",
          "Datos de maestra actualizado"
        );
        formRef.current.resetFields();
        closeModal();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };
  const closeModal = () => {
    setIsVisible(false);
    setSelected(null);
  };

  return (
    <Modal
      title="Editar código de barra"
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        name="editMasterLoad"
        layout="vertical"
        requiredMark={false}
        ref={formRef}
        onFinish={submitForm}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <Item
              name={["masterload", "codigoMaestra"]}
              label="Código"
              initialValue={selected.codigoMaestra}
              rules={[{ required: true }]}
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["masterload", "codigoBarra"]}
              label="Código de barra"
              initialValue={selected.codigoBarra}
              rules={[{ required: true }]}
            >
              <Input maxLength={25} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["masterload", "descripcion"]}
              label="Producto"
              initialValue={selected.descripcion}
              rules={[{ required: true }]}
            >
              <Input maxLength={100} />
            </Item>
          </Col>
          <Col xs={24}>
            <Item
              name={["masterload", "categoria"]}
              label="Categoría"
              initialValue={selected.categoria}
              rules={[{ required: true }]}
            >
              <Select
                options={toOptions([...MasterLoadCategories], "value", "text")}
                loading={!MasterLoadCategories}
              ></Select>
            </Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MasterLoadsProductEdit;
