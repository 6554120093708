export const helperStatus = {
    title: "Estado",
    dataIndex: "habilitado",
    key: "habilitado",
    render: (habilitado) => (habilitado ? "Habilitado" : "Deshabilitado"),
    onFilter: (value, record) =>
        record.habilitado.toString().indexOf(value.toString()) === 0,
    filters: [
        {
            text: "Habilitado",
            value: "true",
        },
        {
            text: "Deshabilitado",
            value: "false",
        },
    ],
};
