export const usersInsertRules = {
  idTipoUsuario: [{ required: true }],
  nombre: [{ required: true }],
  nick: [
    { required: true },
    { pattern: /^[A-Z0-9]+$/i, message: "Usar sólo números y letras" },
  ],
  telefono: [{ required: true }, { len: 9 }],
  correo: [
    { required: true },
    {
      type: "email",
      message: "Correo no válido",
    },
  ],
  password: [{ required: true }],
  habilitado: [{ required: true }],

  confirmPassword: [
    {
      required: true,
      message: "Favor, confirmar la contraseña",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(["user", "password"]) === value) {
          return Promise.resolve();
        }

        return Promise.reject("Las contraseñas no coinciden");
      },
    }),
  ],
};
