import React, { useState } from "react";

import { Form, Input, Button } from "antd";
import { MainLogin } from "../../js/app/auth/login";
import { openNotification } from "../controls/Notifications";
import "../pages/Login.css";

// const layout = {
//   // labelCol: { span: 8 },
//   // wrapperCol: { span: 16 },
// };
// const tailLayout = {
//   wrapperCol: { offset: 8, span: 16 },
// };

const Login = () => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    const { username, password } = values;
    MainLogin(username, password)
      .catch((x) => {
        setLoading(false);
        openNotification("e", "Error de Autentificación", x.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log(`
                            Bienvenido a controlle
                            
                                       (((                                      
                                      *(((.                                     
                                       #(/                                      
                                       *(                                       
            /((#                       *(                        (((,           
             #((((                    .*(,.                    ((((/            
                ,#(,         *((((((((((((((((((((#          ((#.               
                   ,(#   /(((((((((((((((((((((((((((((   .(#                   
                      ((((((((((#      %(      .#(((((((((.                     
                    /((((((##          ((#          ((((((((                    
                   ((((((( %(#        #(((        .(( *(((((((                  
                 .((((((.    ((((#   .,#((/    ((((#    %((((((                 
                 ((((((        (((((# ((((/ #(((((       *((((((                
                ((((((           ((((((((((((((#          %(((((                
             //*((((((       *(####((      /(((((((#/      (((((( /(/   

`);

  return (
    <div className="imagen-fondo">
      <div className="container">
        <div className="login-container">
          <h3>Acceso Trabajadores</h3>
          <Form
            // {...layout}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Usuario"
              name="username"
              rules={[
                { required: true, message: "Por favor, ingrese su usuario" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[
                { required: true, message: "Por favor, ingrese su contraseña" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
