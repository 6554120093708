import { Button } from "antd";
import React, { useContext, useState } from "react";
import ApiUsuarios from "../../../../../js/api/global/Usuarios";
import { UsersContext } from "../../../../../js/app/context/UsersContext";
import { helperStatus } from "../../../../../js/helpers/tableHelpers";
import DateTable from "../../../../controls/DateTable";
import { openNotification } from "../../../../controls/Notifications";
import UsersChangePassword from "./UsersChangePassword";
import UsersEdit from "./UsersEdit";

const UsersList = () => {
  const { users, parametersData, loading } = useContext(UsersContext);

  const { tipoUsuarios } = parametersData;

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [UserSelected, setUserSelected] = useState(null);

  const editUsers = (User) => {
    ApiUsuarios.GetUsuario(User.id).then((resp) => {
      if (resp.success) {
        setUserSelected(resp.data);
        setIsEditVisible(true);
      } else {
        openNotification("e", "Error de carga", resp.message);
      }
    });
  };

  const changePasswordUsers = (User) => {
    ApiUsuarios.GetUsuario(User.id).then((resp) => {
      if (resp.success) {
        setUserSelected(resp.data);
        setIsChangePasswordVisible(true);
      } else {
        openNotification("e", "Error de carga", resp.message);
      }
    });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      search: true,
    },
    {
      title: "Nick",
      dataIndex: "nick",
      key: "nick",
      search: true,
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
      search: true,
    },
    {
      title: "Correo",
      dataIndex: "correo",
      key: "correo",
      search: true,
    },
    {
      title: "TipoUsuario",
      dataIndex: "tipoUsuario",
      key: "tipoUsuario",
      onFilter: (value, record) => record.tipoUsuario.indexOf(value) === 0,
      filters:
        tipoUsuarios?.map((x) => ({
          text: x.descripcion,
          value: x.descripcion,
        })) ?? [],
    },
    helperStatus,
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (User) => (
        <>
          <div className="row">
            <Button
              className="col m-1"
              type="primary"
              onClick={() => editUsers(User)}
            >
              Editar
            </Button>
            <Button
              className="col m-1"
              type="primary"
              danger
              onClick={() => changePasswordUsers(User)}
            >
              Cambiar password
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <DateTable data={users} columns={columns} loading={loading} />
      {UserSelected && (
        <UsersEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          selected={UserSelected}
          setSelected={setUserSelected}
        />
      )}
      {UserSelected && (
        <UsersChangePassword
          isVisible={isChangePasswordVisible}
          setIsVisible={setIsChangePasswordVisible}
          selected={UserSelected}
          setSelected={setUserSelected}
        />
      )}
    </>
  );
};

export default UsersList;
