import { Button } from "antd";
import React, { useContext, useState } from "react";
import ApiProductoSucursals from "../../../../../js/api/global/ProductoSucursals";
import { MasterLoadsContext } from "../../../../../js/app/context/MasterLoadsContext";
import DateTable from "../../../../controls/DateTable";
import { openNotification } from "../../../../controls/Notifications";
import MasterLoadsProductEdit from "./MasterLoadsProductEdit";

const MasterLoadsList = () => {
  const { MasterLoad, MasterLoadCategories, loading } = useContext(
    MasterLoadsContext
  );

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [masterLoadselected, setMasterLoadselected] = useState(null);

  const editProductoSucursal = (inventorie) => {
    ApiProductoSucursals.GetProductoSucursalSelected(inventorie.id).then(
      (resp) => {
        if (resp.success) {
          setMasterLoadselected(resp.data);
          setIsEditVisible(true);
        } else {
          openNotification("e", "Error de inserción", resp.message);
        }
      }
    );
  };

  const columns = [
    {
      title: "Código",
      dataIndex: "codigoMaestra",
      key: "codigoMaestra",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.codigoMaestra - b.codigoMaestra,
      search: true,
    },
    {
      title: "Código barra",
      dataIndex: "codigoBarra",
      key: "codigoBarra",
      search: true,
    },
    {
      title: "Producto",
      dataIndex: "descripcion",
      key: "descripcion",
      search: true,
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
      key: "categoria",
      onFilter: (value, record) => record.categoria.indexOf(value) === 0,
      filters: MasterLoadCategories,
    },
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (productoSucursal) => (
        <Button
          type="primary"
          onClick={() => editProductoSucursal(productoSucursal)}
        >
          Editar
        </Button>
      ),
    },
  ];
  return (
    <>
      <DateTable
        data={MasterLoad}
        columns={columns}
        keyValue="codigoMaestra"
        loading={loading}
      />
      {masterLoadselected && (
        <MasterLoadsProductEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          selected={masterLoadselected}
          setSelected={setMasterLoadselected}
        />
      )}
    </>
  );
};

export default MasterLoadsList;
