import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import ApiTipoUsuarios from "../../../../js/api/global/TipoUsuario";
import ApiUsuarios from "../../../../js/api/global/Usuarios";
import { UsersContext } from "../../../../js/app/context/UsersContext";
import { openNotification } from "../../../controls/Notifications";
import UsersInsert from "./partial/UsersInsert";
import UsersList from "./partial/UsersList";

const Users = () => {
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const [parametersData, setParametersData] = useState({});

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiTipoUsuarios.GetTipoUsuarioList(),
      ApiUsuarios.GetUsuarioList(true),
    ]).then((res) => {
      setLoading(false);

      const tipoUsuario = res[0];
      const usuarios = res[1];

      if (
        tipoUsuario.success &&
        usuarios.success 
      ) {
        const parameters = {
          tipoUsuarios: tipoUsuario.dataList,
        };

        setParametersData(parameters);

        setUsers(usuarios.dataList);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  return (
    <div className="container-xl">
      <h3>Mantenedor de usuarios</h3>
      <Divider dashed />
      <div>
        <UsersContext.Provider
          value={{ users, setUsers, parametersData, loading }}
        >
          <UsersInsert />
          <Divider dashed />
          <UsersList />
        </UsersContext.Provider>
      </div>
    </div>
  );
};

export default Users;
