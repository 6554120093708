import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import ApiEmpresas from "../../../../js/api/global/Empresas";
import ApiProductoSucursals from "../../../../js/api/global/ProductoSucursals";
import ApiSucursals from "../../../../js/api/global/Sucursals";
import { MasterLoadsContext } from "../../../../js/app/context/MasterLoadsContext";
import { openNotification } from "../../../controls/Notifications";
import MasterLoadsFilter from "./partial/MasterLoadsFilter";
import MasterLoadsInsert from "./partial/MasterLoadsInsert";
import MasterLoadsList from "./partial/MasterLoadsList";

const MasterLoads = () => {
  const [loading, setLoading] = useState(false);

  const [MasterLoad, setMasterLoad] = useState([]);
  const [MasterLoadCategories, setMasterLoadCategories] = useState([]);
  const [idEmpresaSelected, setIdEmpresaSelected] = useState("");

  const [parametersData, setParametersData] = useState({});

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiEmpresas.GetEmpresaList(false),
      ApiSucursals.GetSucursalList(false),
    ]).then((res) => {
      setLoading(false);
      const empresas = res[0];
      const sucursales = res[1];

      if (empresas.success && sucursales.success) {
        const parameters = {
          sucursales: sucursales.dataList,
          empresas: empresas.dataList,
        };
        setParametersData(parameters);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  useEffect(() => {
    if (idEmpresaSelected !== "") {
      setLoading(true);
      ApiProductoSucursals.GetProductoSucursalEmpresaList(idEmpresaSelected).then(
        (resp) => {
          setLoading(false);
          setMasterLoad([...resp.dataList]);
          let categories = resp.dataList.map((x) => x.categoria);
          categories = [...new Set(categories)]
          const categoriesObj = categories.map((x) => ({
            text: x,
            value: x,
          }));
          setMasterLoadCategories(categoriesObj);
        }
      );
    }
  }, [idEmpresaSelected]);

  return (
    <div className="container-xl">
      <h3>Carga maestra</h3>
      <Divider dashed />
      <div>
        <MasterLoadsContext.Provider
          value={{
            MasterLoad,
            setMasterLoad,
            MasterLoadCategories,
            setMasterLoadCategories,
            idEmpresaSelected,
            setIdEmpresaSelected,
            parametersData,
            loading,
            setLoading,
          }}
        >
          <MasterLoadsFilter />
          {idEmpresaSelected && <MasterLoadsInsert />}
          <Divider dashed />
          <MasterLoadsList />
        </MasterLoadsContext.Provider>
      </div>
    </div>
  );
};

export default MasterLoads;
