import { IAxios, IAxiosFormData } from "../conf";

const baseEndpoint = "ProductoSucursals/";

const GetProductoSucursalEmpresaList = async (idSucursal) => {
  const endpoint = baseEndpoint + "List/" + idSucursal;

  return await IAxios.get(endpoint);
};

const GetProductoSucursalSelected = async (id) => {
  const endpoint = baseEndpoint + "Selected/" + id;

  return await IAxios.get(endpoint);
};

const PutProductoSucursal = async (id, ProductoSucursal) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.put(endpoint, ProductoSucursal);
};

const PostProductoSucursalByCompany = async (idEmpresa, cargaMaestra) => {
  const endpoint = baseEndpoint + "ByCompany";

  const formData = new FormData();
  formData.append("idEmpresa", idEmpresa);
  formData.append("cargaMaestra", cargaMaestra);

  return await IAxiosFormData.post(endpoint, formData);
};


const PostProductoSucursalByCompanyUnit = async (productoSucursal) => {
  const endpoint = baseEndpoint + "ByCompanyUnit";

  return await IAxios.post(endpoint, productoSucursal);
};


const ApiProductoSucursals = {
  GetProductoSucursalEmpresaList,
  GetProductoSucursalSelected,
  PutProductoSucursal,
  PostProductoSucursalByCompany,
  PostProductoSucursalByCompanyUnit
};

export default ApiProductoSucursals;
