import { IAxios } from "../conf";

const baseEndpoint = "Regions/";

const GetRegion = async () => {
  return await IAxios.get(baseEndpoint);
};

const ApiRegions = {
  GetRegion,
};

export default ApiRegions;
