import { IAxios } from "../conf";

const baseEndpoint = "Sucursals/";

const GetSucursalList = async (all = true) => {
  const endpoint = baseEndpoint + "List/" + (all? "true": "");

  return await IAxios.get(endpoint);
};

const GetSucursal = async (id) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.get(endpoint);
};

const PutSucursal = async (id, sucursal) => {
  const endpoint = baseEndpoint + id;

  return await IAxios.put(endpoint, sucursal);
};

const PostSucursal = async (sucursal) => {
  return await IAxios.post(baseEndpoint, sucursal);
};

const ApiSucursals = {
  GetSucursalList,
  GetSucursal,
  PutSucursal,
  PostSucursal,
};

export default ApiSucursals;
