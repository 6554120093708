import React, { useContext, useRef } from "react";
import { Form, Row, Select, Col } from "antd";
import { toOptions } from "../../../../../js/helpers/helpers";
import { MasterLoadsContext } from "../../../../../js/app/context/MasterLoadsContext";

const { Item } = Form;

const MasterLoadsFilter = () => {
  const { parametersData, setMasterLoad, setIdEmpresaSelected } =
    useContext(MasterLoadsContext);

  const { empresas } = parametersData;

  const formRef = useRef();

  const changeEmpresa = (value) => {
    formRef.current.setFields([
      { name: ["MasterLoads", "idSucursal"], value: "" },
    ]);
    setIdEmpresaSelected(value);
    setMasterLoad([]);
  };

  return (
    <Form
      name="filterInventory"
      layout="vertical"
      requiredMark={true}
      ref={formRef}
    >
      <Row gutter={[24, 16]}>
        <Col md={24} xs={24}>
          <Item label="Empresa" name={["MasterLoads", "idEmpresa"]} tooltip="Selecciona una empresa" required>
            <Select
              options={toOptions(empresas, "id", "nombre")}
              loading={!empresas}
              onChange={changeEmpresa}
            ></Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MasterLoadsFilter;
