import { ConfigProvider } from "antd";
import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import LayoutPrivate from "./components/layouts/LayoutPrivate";
import BranchOffices from "./components/pages/admin/BranchOffices/BranchOffices";
import { antDesingValidateMessages } from "./js/lang/es/AntDesingValidateMessages";

import "./custom.css";
import { getSessionAccount } from "./js/helpers/helpersSession";
// import LayoutPublic from "./components/layouts/LayoutPublic";
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import Inventories from "./components/pages/admin/Inventories/Inventories";
import MasterLoads from "./components/pages/admin/MasterLoad/MasterLoads";
import Users from "./components/pages/admin/Users/Users";
import Companies from "./components/pages/admin/Business/Companies";
import SelectInventorie from "./components/pages/admin/SelectInventorie/SelectInventorie";
import CountInventorie from "./components/pages/admin/CountInventorie/CountInventorie";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ConfigProvider form={{ antDesingValidateMessages }}>
        <BrowserRouter>
          {getSessionAccount("token") ? (
            <LayoutPrivate>
              <Switch>
              <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/inventories" component={Inventories} />
                <Route exact path="/selectinv" component={SelectInventorie} />
                <Route exact path="/countinv/:id" component={CountInventorie} />
                <Route exact path="/companies" component={Companies} />
                <Route exact path="/branchoffices" component={BranchOffices} />
                <Route exact path="/masterload" component={MasterLoads} />
              </Switch>
            </LayoutPrivate>
          ) : (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route path="*" component={Login} />
            </Switch>
          )}
        </BrowserRouter>
      </ConfigProvider>
    );
  }
}
