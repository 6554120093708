import { IAxios } from "../conf";

const baseEndpoint = "Comunas/";

const Getcomuna = async () => {
  return await IAxios.get(baseEndpoint);
};

const ApiComunas = {
  Getcomuna,
};

export default ApiComunas;
