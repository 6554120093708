import { IAxiosFormData } from "../conf";

const login = async (user, pass) => {
  const formData = new FormData();
  formData.append("nick", user);
  formData.append("pass", pass);

  const endpoint = "Login/Login";

  return await IAxiosFormData.post(endpoint, formData);
};

const ApiLogin = {
  login,
};

export default ApiLogin;
