import React, { useContext, useRef, useState } from "react";
import { Button, Form, Input, Row, Select, Col } from "antd";
import { openNotification } from "../../../../controls/Notifications";
import { UsersContext } from "../../../../../js/app/context/UsersContext";
import { toOptions } from "../../../../../js/helpers/helpers";
import { usersInsertRules } from "../../../../../js/validations/admin/UsersInsertRules";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ApiUsuarios from "../../../../../js/api/global/Usuarios";

const { Item } = Form;

const UsersInsert = () => {
  const [loading, setLoading] = useState(false);

  const { parametersData, setUsers, users } = useContext(UsersContext);

  const { tipoUsuarios } = parametersData;

  const formRef = useRef();

  const submitForm = (data) => {
    setLoading(true);
    ApiUsuarios.PostUsuario(data.user).then((resp) => {
      setLoading(false);
      if (resp.success) {
        setUsers([...users, resp.data]);

        openNotification(
          "s",
          "Inserción correcta",
          "Se ha ingresado el usuario correctamente"
        );
        formRef.current.resetFields();
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  return (
    <Form
      name="addUser"
      layout="vertical"
      requiredMark={false}
      ref={formRef}
      onFinish={submitForm}
    >
      <Row gutter={[24, 16]}>
        <Col md={12} xs={24}>
          <Item
            name={["user", "nombre"]}
            label="Nombre"
            rules={usersInsertRules.nombre}
          >
            <Input maxLength={75} />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "nick"]}
            label="Nick"
            rules={usersInsertRules.nick}
          >
            <Input maxLength={25} />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "telefono"]}
            label="Telefono"
            style={{ display: "block" }}
            rules={usersInsertRules.telefono}
          >
            <Input type="number" placeholder="933556689" />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "correo"]}
            label="Correo"
            rules={usersInsertRules.correo}
          >
            <Input maxLength={75} />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "password"]}
            label="Contraseña"
            hasFeedback
            rules={usersInsertRules.password}
          >
            <Input.Password
              maxLength={25}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "confirmPassword"]}
            label="Confirmar contraseña"
            hasFeedback
            rules={usersInsertRules.confirmPassword}
          >
            <Input.Password
              maxLength={25}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Item>
        </Col>
        <Col md={12} xs={24}>
          <Item
            name={["user", "idTipoUsuario"]}
            label="TipoUsuario"
            rules={usersInsertRules.idTipoUsuario}
          >
            <Select
              options={toOptions(tipoUsuarios, "id", "descripcion")}
              loading={!tipoUsuarios}
            ></Select>
          </Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UsersInsert;
