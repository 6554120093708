import helperSession from "../../helpers/helpersSession"

import {navigationAdministrador} from "./administrador";
import {navigationSupervisor} from "./supervisor";
import {navigationContador} from "./contador";

export const getNavigations = () => {

    const rol = helperSession.GetAccount("rol")
    let navigation = [];
    switch (rol) {
        case "Admin":
            navigation = navigationAdministrador
            break;
        case "Supervisor":
            navigation = navigationSupervisor
            break;
        case "Contador":
            navigation = navigationContador
                break;
        default:
            navigation = navigationContador
            break;
    }
    return navigation;
}