import { Button } from "antd";
import React, { useContext, useState } from "react";
import ApiEmpresas from "../../../../../js/api/global/Empresas";
import { CompaniesContext } from "../../../../../js/app/context/CompaniesContext";
import { helperStatus } from "../../../../../js/helpers/tableHelpers";
import DateTable from "../../../../controls/DateTable";
import { openNotification } from "../../../../controls/Notifications";
import CompaniesEdit from "./CompaniesEdit";

const CompaniesList = () => {
  const { companies, loading } = useContext(CompaniesContext);

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [companySelected, setCompanyselected] = useState(null);

  const editCompanies = (branchOffice) => {
    ApiEmpresas.GetEmpresa(branchOffice.id).then((resp) => {
      if (resp.success) {
        setCompanyselected(resp.data);
        setIsEditVisible(true);
      } else {
        openNotification("e", "Error de inserción", resp.message);
      }
    });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      search: true,
    },
    helperStatus,
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (company) => (
        <Button type="primary" onClick={() => editCompanies(company)} >Editar</Button>
      ),
    },
  ];

  return (
    <>
      <DateTable
        data={companies}
        columns={columns}
        keyValue="id"
        loading={loading}
      />
      {companySelected && (
        <CompaniesEdit
          isVisible={isEditVisible}
          setIsVisible={setIsEditVisible}
          selected={companySelected}
          setSelected={setCompanyselected}
        />
      )}
    </>
  );
};

export default CompaniesList;
