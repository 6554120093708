import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import ApiComunas from "../../../../js/api/global/Comunas";
import ApiEmpresas from "../../../../js/api/global/Empresas";
import ApiRegions from "../../../../js/api/global/Regions";
import ApiSucursals from "../../../../js/api/global/Sucursals";
import { BranchOfficesContext } from "../../../../js/app/context/BranchOfficesContext";
import { openNotification } from "../../../controls/Notifications";
import BranchOfficesInsert from "./partial/BranchOfficesInsert";
import BranchOfficesList from "./partial/BranchOfficesList";

const BranchOffices = () => {
  const [loading, setLoading] = useState(false);

  const [branchOffices, setBranchOffices] = useState([]);

  const [parametersData, setParametersData] = useState({});

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiRegions.GetRegion(),
      ApiComunas.Getcomuna(),
      ApiEmpresas.GetEmpresaList(false),
      ApiSucursals.GetSucursalList(),
    ]).then((res) => {
      setLoading(false);

      const regions = res[0];
      const comunas = res[1];
      const empresas = res[2];
      const sucursales = res[3];

      if (
        regions.success &&
        comunas.success &&
        empresas.success &&
        sucursales.success
      ) {
        const parameters = {
          regions: regions.dataList,
          comunas: comunas.dataList,
          empresas: empresas.dataList,
        };

        setParametersData(parameters);

        setBranchOffices(sucursales.dataList);
      } else {
        openNotification(
          "e",
          "Carga de datos",
          "Ha ocurrido un error al cargar la data"
        );
      }
    });
  }, []);

  return (
    <div className="container-xl">
      <h3>Mantenedor sucursales</h3>
      <Divider dashed />
      <div>
        <BranchOfficesContext.Provider
          value={{ branchOffices, setBranchOffices, parametersData, loading }}
        >
          <BranchOfficesInsert />
          <Divider dashed />
          <BranchOfficesList />
        </BranchOfficesContext.Provider>
      </div>
    </div>
  );
};

export default BranchOffices;
